import { default as React } from 'react';
import { AppHeader } from '~/components/app-header';
import { AppLayout } from '~/components/AppLayout';
import HeroSlides from '~/components/hero/hero-slides';
import { Loadable } from '~/components/loadable.tsx';
import ProductList, {
  ProductsLoaderIndicator,
} from '~/components/product-list/product-list.tsx';
import { SidebarRight } from '~/components/sidebar';
import { SidebarDefault } from '~/components/sidebar/sidebar-default.tsx';
import { Meta } from '~/state/router/metaTags.tsx';

const images = [
  {
    src: '/banners/tricot-premium.webp#0',
    alt: 'Tricot premium em promoção!',
    href: '/products/conjunto-tricot-monocromatico-detalhe-trancas-dosiere',
  },
  {
    src: '/banners/banner-amigopet-natal.webp#1',
    alt: 'Ração Magnus + Brinde repelente',
    href: '#',
  },
  {
    src: '/banners/natal-tropical-2.webp#0',
    alt: 'Tricot premium em promoção!',
    href: '/products/regata-tricot-cropped-com-plumas-na-barra-dosiere',
  },
];

export default function Home() {
  return (
    <AppLayout
      navbar={<AppHeader />}
      hero={<HeroSlides images={images} />}
      left={<SidebarDefault />}
      right={<SidebarRight />}
      main={
        <Loadable loadingIndicator={<ProductsLoaderIndicator />}>
          <ProductList
            infinite
            method={'getCollectionProducts'}
            variables={{ collection: 'hidden-featured-home', first: 50 }}
            title={null}
          />
        </Loadable>
      }
    />
  );
}

function Metadata() {
  return (
    <Meta
      value={{
        title: 'ZAZCART | Início',
        description: '',
      }}
    />
  );
}
