import './slides.scss';

import { styled } from '@linaria/react';
import { ClassValue, cx, useRendered } from '@zazcart/commons';
import { Link } from '@zazcart/ui';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import React, { startTransition, useEffect, useState } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const FixMarginBottom = styled.div`
  display: block;
  width: 100%;
  min-width: 100%;
  margin-top: -12px;
  margin-bottom: 12px;
`;

export default function HeroSlides(props: {
  className?: ClassValue;
  images: { src: string; alt: string; href: string }[];
}) {
  const { className, images } = props;
  const [emblaRef, api] = useEmblaCarousel({ loop: true }, [Autoplay()]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [justStarted, setJustStarted] = useState(true);
  const rendered = useRendered();

  // duplicating to empty left and right spaces
  const slides =
    images.length === 2 ? [images[0], images[1], images[0], images[1]] : images;

  const count = slides?.length || 0;

  useEffect(() => {
    if (!api) return;

    setTimeout(() => {
      startTransition(() => {
        setJustStarted(false);
      });
    }, 1000);

    api.on('select', () => {
      setActiveSlide(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <Wrapper>
      <div className={cx('HeroSlides', className)}>
        <div
          className={cx(
            //
            'block w-full',
            `count-${count}`,
            { single: count === 1, 'not-started': !rendered },
            props.className,
            'hero-slider',
          )}
          ref={rendered ? emblaRef : undefined}
        >
          <div
            chrome-discard-layout-jank={''}
            data-layout-shift-exempt={''}
            className={cx('carousel-container', {
              'not-started': !rendered,
              'just-started': justStarted,
            })}
          >
            {slides.map((el, idx) => {
              return (
                <Link
                  className="slider__slide"
                  key={`${el.src}${el.alt}${idx}`}
                  href={el.href}
                  accessibilityLabel={el.alt}
                >
                  <img
                    className={cx('slide__image transition-opacity', {
                      inactive: idx !== activeSlide,
                      active: idx == activeSlide,
                    })}
                    src={el.src}
                    alt={el.alt}
                  />
                </Link>
              );
            })}
          </div>
        </div>

        <div className="slides-pagination">
          <div className="slides-pagination__container">
            {slides.map((_slide, idx) => {
              return (
                <Link
                  accessibilityLabel={`Go to slide ${idx + 1}`}
                  href={'#'}
                  key={idx}
                  title={`Exibir slide ${idx + 1}`}
                  className={cx('slide-dot-button', {
                    active: idx === activeSlide,
                  })}
                  role={'button'}
                  onClick={(ev) => {
                    ev.preventDefault();
                    api?.scrollTo(idx);
                  }}
                >
                  <span className="slide-dot"></span>
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <FixMarginBottom />
    </Wrapper>
  );
}
